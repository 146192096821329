import React, { useState, useEffect, useRef } from 'react'
import {
  DatePicker,
  Space,
  TimePicker,
  Form,
  Input,
  Button,
  message,
  BackTop,
  Spin,
  Progress,
  Popconfirm,
  Select,
  Typography,
  Modal,
  notification,
} from 'antd'
import moment from 'moment'
import axios from 'axios'
import {
  DeleteOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons'
// import messages from '../../utils/messages'
import MyStopwatch from '../../components/home/MyStopwatch'

const { RangePicker } = DatePicker
const { Option } = Select
const { Text, Paragraph } = Typography
const messages = ['We need you to show us what we saw during the interview']
let url
if (process.env.REACT_APP_ENV === 'development') {
  url = process.env.REACT_APP_SERVER_DEVELOPEMENT
} else {
  url = process.env.REACT_APP_SERVER_PRODUCTION
}
console.log(url)
const todaysDateString = moment().format().substring(0, 10)

function Home() {
  const [date, setDate] = useState(null)
  const [subject, setSubject] = useState('ark')
  const [target, setSetTarget] = useState('3')
  const [user, setUser] = useState('kamal.kh0098@gmail.com')
  const [tracks, setTracks] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [started, setStarted] = useState(false) // start off toogle button
  const [saveToDbLoading, setSaveToDbLoading] = useState(false)
  const [topBanner, setTopBanner] = useState(
    messages[Math.floor(Math.random() * messages.length)]
  )
  const lastElementRef = useRef()

  function addRow(s, e) {
    setTracks([...tracks, { start: s ? s : '', end: e ? e : '', details: '' }])
    if (lastElementRef.current) {
      lastElementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }

  async function removeRow(idx) {
    // Remove from row from array
    const newTracks = tracks.filter((el, index) => {
      return index != idx
    })

    setTracks([])

    // Required to bug free re render after deleting middle elemnts of array
    // Otherwise without this timeout the row is removing at DB fine, but showing wrong render elements on UI
    setTimeout(() => {
      setTracks(newTracks)
    }, 500)
  }

  async function onChange(date, dateString, index, type) {
    // When top date changed by user
    if (!type && dateString) {
      setDate(dateString)
      setTracks([]) // removing previous tracks
      setTotal(0) // remove previous total

      const secret = localStorage.getItem('secret')
      if (!secret) {
        alert('Please enter correct password and refresh')
        return
      }

      // Update data on UI every date change
      const response = await axios({
        method: 'GET',
        url: `${url}/api/getData/${user}/${secret}/${dateString}/${subject}`,
      })
      // console.log(response.data)

      if (response.data.data?.tracks) {
        setTracks(response.data.data.tracks) // updating tracks based on query response
      }
    } else if (!type && !dateString) {
      setDate(null)
      setTracks([])
      setTotal(0)
    }

    // Update strating time
    if (type === 'start') {
      const updated = tracks.map((el, idx) => {
        if (idx === index) {
          return { ...el, start: date }
        } else return el
      })
      setTracks(updated)
    }

    // Update ending time
    if (type === 'end') {
      const updated = tracks.map((el, idx) => {
        if (idx === index) {
          return { ...el, end: date }
        } else return el
      })
      setTracks(updated)
    }
  }

  let timeout

  function onDescriptionChange(evt, index) {
    clearTimeout(timeout) // if typed within half seconds then dont update the track at DB (to avoid redundant update)
    // Update details of a track object
    timeout = setTimeout(() => {
      const updated = tracks.map((el, idx) => {
        if (idx === index) {
          return { ...el, details: evt.target.value }
        } else return el
      })
      setTracks(updated)
    }, 500)
  }

  const fetchData = async (d, s) => {
    const secret = localStorage.getItem('secret')
    if (!secret) {
      alert('Please enter correct password and refresh')
      return
    }
    const dToSearch = d ? d : todaysDateString
    setDate(dToSearch)

    setLoading(true)

    // Search for data after first render
    const response = await axios({
      method: 'GET',
      url: `${url}/api/getData/${user}/${secret}/${dToSearch}/${
        s ? s : subject // when subject changed from "Select" use that, do not use the state value
      }`,
    })
    // console.log(response.data)
    if (response.data.data?.tracks) {
      setTracks(response.data.data.tracks)
      // Calculate the total time and show on top
      let total = 0
      response.data.data.tracks.forEach((el, idx) => {
        if (el.end && el.start) {
          total =
            total +
            moment.duration(moment(el.end).diff(moment(el.start)))._milliseconds
        }
      })
      console.log(`Total time after fetching: ${total}`)
      setTotal(total)
      document.title = msToTime(total)

      setLoading(false)
    } else {
      setTracks([]) // remove previous data
    }
    setLoading(false)
    if (lastElementRef.current) {
      lastElementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }

  const saveToDb = async () => {
    if (date) {
      // Update at DB
      setSaveToDbLoading(true)
      try {
        const secret = localStorage.getItem('secret')
        if (!secret) {
          alert('Please enter correct password and refresh')
          return
        }

        const obj = { date, subject, user, tracks, tempPassword: secret }
        // console.log(obj)

        const response = await axios({
          method: 'POST',
          url: `${url}/api/updateData`,
          data: obj,
        })

        info()
        // console.log(response.data)
        // Add total time from 'obj' loop like first time`
        let total = 0
        obj.tracks.forEach((el, idx) => {
          if (el.end && el.start) {
            total =
              total +
              moment.duration(moment(el.end).diff(moment(el.start)))
                ._milliseconds
          }
        })
        console.log(`Total time after saveToDb(): ${total}`)
        setTotal(total)
        document.title = msToTime(total)

        setSaveToDbLoading(false)
      } catch (err) {
        console.log(err)
        setSaveToDbLoading(false)
      }
    }
  }
  const toggleTime = () => {
    // create row if btn is not started
    if (!started) {
      addRow(moment())
      setStarted(true)
    } else {
      // end time in the row

      onChange(moment(), moment().format('HH:mm:ss'), tracks.length - 1, `end`)

      setStarted(false)

      notification[`info`]({
        message: 'Ctrl + r',
        placement: 'topRight',
        duration: 1.5,
        description: 'Please click on "Refresh" after "Stop" button clicked',
      })
    }
  }

  const keydownHandler = (e) => {
    if (e.keyCode === 83) {
      return
      toggleTime() // Currently causing bug need more work to fix this
    }
    if (e.keyCode === 82 && e.ctrlKey) fetchData(date)
  }

  // Update on any track time change or delete track row
  useEffect(() => {
    ;(async () => {
      saveToDb()
    })()
  }, [tracks])

  // Only first one time useEffect
  useEffect(() => {
    document.addEventListener('keydown', keydownHandler)
    // console.log('Only first time useEffect')

    fetchData()

    // alert(detectMob())

    let autoNotification

    const send = () => {
      var options = {
        // body: messages[Math.floor(Math.random() * messages.length)],
        icon: 'https://i.ibb.co/mTPgxmL/Screenshot-2022-01-01-122904.png',
        dir: 'ltr',
      }

      // try catch mainly for android (notification not working on a20s, android 11 at 1-1-2022)
      try {
        // alert('try notification')
        new Notification(
          messages[Math.floor(Math.random() * messages.length)],
          options
        )
      } catch (err) {
        console.log(JSON.stringify(err))
        // alert(JSON.stringify(err))
      }
      // * Auto close later if needed suggestions => (https://www.pluralsight.com/guides/html5-desktop-notifications-with-react)
    }

    // Check if the browser support notification
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification')
    } else {
      console.log('Desktop notifications are supported in this browser')
      // alert('Desktop notifications are supported in this browser')

      // Take permission for first time in a browser
      Notification.requestPermission()

      // One immediate notification
      send()
      notification[`info`]({
        message: messages[Math.floor(Math.random() * messages.length)],
        placement: 'topRight',
        duration: 3,
        // description: messages[Math.floor(Math.random() * messages.length)],
      })

      // Notification in every 10 minutes
      autoNotification = setInterval(() => {
        console.log('Auto notification started.')
        // send() // commenting this out because now, useing Notification app at mobile
      }, 180000) // every 3 minutes

      // TODO: Clear timer for it later like the one above
      setInterval(() => {
        notification[`info`]({
          message: messages[Math.floor(Math.random() * messages.length)],
          placement: 'topRight',
          duration: 1.5,
          // description: messages[Math.floor(Math.random() * messages.length)],
        })
      }, 180000) // every 3 minutes

      // TODO: Clear timer for it later like the one above
      setInterval(() => {
        setTopBanner(messages[Math.floor(Math.random() * messages.length)])
      }, 10000)
    }

    return () => {
      if (autoNotification) clearInterval(autoNotification)
    }
  }, [])

  // this useEffect, only for document title update for now
  useEffect(() => {
    document.title = msToTime(total)
  }, [total])

  function onPassChange(evt, index) {
    // console.log(evt.target.value)
    localStorage.setItem('secret', evt.target.value)
  }

  function handleSubjectChange(value) {
    console.log(`selected ${value}`)
    fetchData(date, value)
    setSubject(value)
    if (value === 'Scaledex Work') {
      setSetTarget(3)
    } else if (value === 'ark') {
      setSetTarget(3)
    } else if (value === 'Upgrade Skills') {
      setSetTarget(1)
    } else if (value === 'Flog') {
      setSetTarget(8)
    }
  }

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <Spin size="large" />{' '}
        <Input.Password
          style={{ marginTop: 20 }}
          defaultValue={localStorage.getItem('secret')}
          placeholder="Enter your secrect password"
          onChange={(e) => onPassChange(e)}
          visibilityToggle={false}
        />
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          padding: 5,
          marginLeft: 60,
          marginRight: 60,
          width: 1000,
          maxWidth: 1000,
        }}
      >
        <BackTop />
        <Modal
          title="Please wait saving to db..."
          visible={saveToDbLoading}
          footer={null}
          closable={false}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
          }}
        >
          <Button
            block
            type="primary"
            shape="circle"
            size="large"
            color="red"
            danger={!started ? false : true}
            onClick={() => {
              toggleTime()
            }}
          >
            {/* {tracks.length > 0 ? tracks[tracks?.length - 1].end : ''} */}

            {started ? 'Stop' : 'Start'}
          </Button>
        </div>
        <div>{started && <MyStopwatch total={total} />}</div>
        {/* <h3 style={{ textAlign: 'end' }}>{topBanner}</h3> */}
        <h1>{msToTime(total)}</h1>

        <div style={{ display: 'flex' }}>
          <p style={{ fontSize: 8 }}>
            <span style={{ color: '#030' }}>Total =</span> {msToTime(total)}{' '}
            <Paragraph copyable style={{ display: 'inline', color: '#030' }}>
              {total}
            </Paragraph>
            {/* <span style={{ color: total < 3600000 * target ? 'red' : 'green' }}>
            {msToTime(total)}
          </span> */}
          </p>

          {/* <Progress
          percent={((total / (3600000 * target)) * 100).toFixed(2)}
          // steps={5}
          trailColor="#E35132"
          strokeColor="#52c41a"
          style={{ marginLeft: 10, width: '50%' }}
          showInfo
        /> */}

          {/* <p style={{ marginBottom: 0, marginLeft: 17 }}>
          {' '}
          Target {target} hours
        </p> */}
        </div>

        {date && tracks.length === 0 && (
          <h3>No record found on that date. For this subject.</h3>
        )}

        <div style={{ height: 350, overflowY: 'scroll' }}>
          {tracks.map((el, idx) => {
            return (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  boxShadow: `0px 2px 5px 0px ${
                    idx % 2 == 0 ? `#c4c4c4` : `#c4c4c4`
                  }`,
                }}
                ref={tracks.length === idx + 1 ? lastElementRef : null}
              >
                <TimePicker
                  use12Hours
                  defaultValue={el.start ? moment(el.start) : null}
                  onChange={(dateString, date) => {
                    onChange(dateString, date, idx, `start`)
                  }}
                />
                <TimePicker
                  use12Hours
                  defaultValue={el.end ? moment(el.end) : null}
                  onChange={(dateString, date) =>
                    onChange(dateString, date, idx, `end`)
                  }
                />
                <Popconfirm
                  title="Are you sure to delete this?"
                  onConfirm={() => removeRow(idx)}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="outline"
                    danger
                    icon={<DeleteOutlined />}
                  ></Button>
                </Popconfirm>
                {el.start && el.end && (
                  <p style={{ display: 'inline', marginLeft: 5 }}>
                    Total:{' '}
                    <span style={{ color: 'aqua' }}>
                      {msToTime(
                        moment.duration(moment(el.end).diff(moment(el.start)))
                          ._milliseconds
                      )}
                    </span>
                    Miliseconds:{' '}
                    <Paragraph copyable style={{ display: 'inline' }}>
                      {
                        moment.duration(moment(el.end).diff(moment(el.start)))
                          ._milliseconds
                      }
                    </Paragraph>
                  </p>
                )}
                <Input.TextArea
                  defaultValue={el.details}
                  placeholder="Add details (Optional)"
                  onChange={(e) => onDescriptionChange(e, idx)}
                />
              </div>
            )
          })}
        </div>
        <div
          style={{
            marginTop: 10,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => fetchData(date)}
            type="link"
            style={{ color: '#fff' }}
          >
            Refresh
          </Button>{' '}
          <Button
            block
            size="large"
            color="red"
            danger={!started ? false : true}
            type={!started ? 'outline' : 'primary'}
            onClick={() => {
              toggleTime()
            }}
          >
            {/* {tracks.length > 0 ? tracks[tracks?.length - 1].end : ''} */}

            {started ? 'Stop' : 'Start'}
          </Button>
          <Button
            onClick={() => fetchData(date)}
            type="link"
            style={{ color: '#fff' }}
          >
            Refresh
          </Button>
        </div>

        <div
          style={{ border: '1px solid #f3f3f3', padding: 10, marginTop: 20 }}
        >
          {date && (
            <p>
              <Button onClick={() => addRow()} type="outline">
                Add Row
              </Button>{' '}
              Total entries: {tracks.length}
            </p>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid white',
            marginTop: 20,
            padding: 10,
          }}
        >
          <p style={{ marginRight: 5 }}>
            Input details for <Text type="success">{subject}</Text>{' '}
          </p>
          <div>
            <Select
              defaultValue={subject}
              style={{ width: 150 }}
              onChange={handleSubjectChange}
            >
              <Option value="Flog">Flog</Option>
              <Option value="Scaledex Work">Scaledex Work</Option>
              <Option value="Upgrade Skills">Upgrade Skills</Option>
              <Option value="ark">ark</Option>
            </Select>
          </div>
        </div>

        <div
          style={{ border: '1px solid #f3f3f3', padding: 10, marginTop: 20 }}
        >
          <DatePicker
            defaultValue={date ? moment(date) : moment()}
            onChange={onChange}
          />
          <Button
            onClick={() => {
              setDate(
                moment(date).subtract(1, 'days').format().substring(0, 10)
              )
              fetchData(
                moment(date).subtract(1, 'days').format().substring(0, 10)
              )
            }}
            type="outline"
            icon={<StepBackwardOutlined />}
          ></Button>
          <Button
            onClick={() => {
              setDate(moment(date).add(1, 'days').format().substring(0, 10))
              fetchData(moment(date).add(1, 'days').format().substring(0, 10))
            }}
            type="outline"
            icon={<StepForwardOutlined />}
          ></Button>
        </div>

        <Input.Password
          style={{ marginTop: 20 }}
          defaultValue={localStorage.getItem('secret')}
          placeholder="Enter your secrect password"
          onChange={(e) => onPassChange(e)}
          visibilityToggle={false}
        />
      </div>
    </div>
  )
}

const styles = {
  loadingContainer: {
    margin: '20px 0',
    marginBottom: '20px',
    padding: '30px 50px',
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
  },
}

export default Home

function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000)
  var seconds = ((millis % 60000) / 1000).toFixed(0)
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

function msToTime(duration) {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  // return hours + ':' + minutes + ':' + seconds + '.' + milliseconds
  return hours + ':' + minutes + ':' + seconds
}

const info = () => {
  message.destroy()
  message.info('Auto saved!', 0.5)
}

function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}
