import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Home from '../pages/home/Home.js'
import NotFound from '../pages/404/NotFound'

const Routes = ({ ...props }) => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />

        <Route component={NotFound} />
      </Switch>
    </>
  )
}

export default Routes
