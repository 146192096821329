import { Provider } from 'react-redux'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
// import './App.css';
import './App.less'
import { Button } from 'antd'
import React from 'react'
import store from './redux/store'
import Routes from './routes/Routes'

function App() {
  return (
    <Provider store={store}>
    <Router>
      <Switch>
        <Route component={Routes} />
      </Switch>
    </Router>
  </Provider>
  );
}

export default App;
