import React from 'react'
import { useStopwatch } from 'react-timer-hook'

function MyStopwatch({ total }) {
  const stopwatchOffset = new Date()
  
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({
      autoStart: true,
      offsetTimestamp: stopwatchOffset.setSeconds(
        stopwatchOffset.getSeconds() + total / 1000
      ),
    })

  document.title = `${hours}:${minutes}:${seconds}`

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontSize: '30px' }}>
        <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:
        <span>{seconds}</span>
      </div>
    </div>
  )
}

export default MyStopwatch
